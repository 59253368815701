import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import parse, { domToReact } from "html-react-parser";
import { Helmet } from "react-helmet";

import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";
import "../assets/styles/theme.scss";

import Header from "../components/header.js";
import Footer from "../components/footer.js";

import SelectCustom from "../components/select-custom.js";
import VideoPlayer from "../components/video-player.js";
import FormAjax from "../components/form-ajax.js";
import CarouselCard from "../components/carousel-card.js";
import CarouselBanner from "../components/carousel-banner.js";
import CTAPopupForm from "../components/cta-popup-form.js";
import CTAPopupFormClose from "../components/cta-popup-form-close.js";
import CountUp from "../components/count-up.js";
import AnchorLink from "../components/anchor-link";

const PreviewPage = ({ data: { site } }) => {
  const [post, setPost] = useState(false);

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const postId = queryParameters.get("id");

    if (Number(postId) > 0) {
      try {
        fetch(
          `${site?.siteMetadata?.cmsURL}/wp-json/post-preview/v1/post?id=${postId}`
        )
          .then(response => response.json())
          .then(resultData => {
            if (resultData?.databaseId) setPost(resultData);
            else setPost(false);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  }, []);

  const styleTheme1 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/style.min.css?ver=2.5.0`;
  const styleTheme2 = `${site?.siteMetadata?.cmsURL}/wp-content/themes/hello-elementor/theme.min.css?ver=2.5.0`;
  const styleElementorFrontEnd = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/css/frontend-lite.min.css?ver=3.6.0`;
  const styleElementorConfig = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/post-21.css`;
  const styleElementorIcons = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css?ver=5.15.0`;
  const styleElementorFontAwesome = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3`;
  const styleElementorFASolid = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/solid.min.css?ver=5.15.3`;
  const styleElementorBrands = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor/assets/lib/font-awesome/css/brands.min.css?ver=5.15.3`;
  const styleElementorPro = `${site?.siteMetadata?.cmsURL}/wp-content/plugins/elementor-pro/assets/css/frontend-lite.min.css?ver=3.6.4`;
  const styleElementorGlobal = `${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/global.css}`;

  const parserOptions = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }

      if (attribs?.class?.includes("select-custom")) {
        return <SelectCustom />;
      }

      if (attribs?.class?.includes("icon-video-play")) {
        const video = attribs?.["video-url"];
        const referrerId = attribs?.["referrer-id"] || false;
        return (
          <VideoPlayer
            video={video}
            referrerId={referrerId}
            componentId={attribs?.id}
            componentClass={attribs?.class}
            innerComponent={domToReact(children)}
          />
        );
      }

      if (attribs?.class?.includes("elementor-form")) {
        return (
          <FormAjax
            componentClass={attribs?.class}
            innerComponent={domToReact(children)}
          />
        );
      }

      if (attribs?.class?.includes("carousel-card")) {
        return (
          <CarouselCard
            componentClass={attribs?.class}
            sliderComponent={domToReact(children)}
          />
        );
      }

      if (attribs?.class?.includes("carousel-banner")) {
        return (
          <CarouselBanner
            componentClass={attribs?.class}
            sliderComponent={domToReact(children, parserOptions)}
          />
        );
      }

      if (attribs?.class?.includes("cta-popup-form")) {
        return (
          <CTAPopupForm
            componentClass={attribs?.class}
            ctaComponent={domToReact(children)}
          />
        );
      }

      if (attribs?.class?.includes("form-popup-close")) {
        return (
          <CTAPopupFormClose
            componentClass={attribs?.class}
            ctaComponent={domToReact(children)}
          />
        );
      }

      if (attribs?.class?.includes("count-up")) {
        return (
          <CountUp
            count={attribs?.count || 0}
            duration={attribs?.duration || 2}
            prefix={attribs?.prefix || ""}
            suffix={attribs?.suffix || ""}
            decimal={attribs?.decimal || ""}
            decimals={attribs?.decimals || 0}
            componentClass={attribs?.class}
          />
        );
      }
      if (attribs?.class?.includes("anchor-link")) {
        // return <div onClick={() => scrollTo("#section-claim")}>asd</div>;
        return <AnchorLink attribs={attribs} children={domToReact(children)} />;
      }
    },
  };

  const cssVersion = "202405261056";
  // const cssVersion = Date.now();
  const preLoadedCSS = [
    3691,
    190,
    1220,
    1214,
    1180,
    1184,
    232,
    1533,
    1494,
    1497,
    2071,
    2074,
    1500,
    39,
    2135,
    2175,
    1704,
    401,
    404,
    2818,
    2770,
    3648,
    1710,
    1732,
    1750,
    4554,
    5191,
    5816,
  ];

  return (
    <>
      <div id={`page-${post.databaseId}`} className="page-container">
        <Helmet
          bodyAttributes={{
            class: `elementor-default elementor-page elementor-page-${post.databaseId} elementor-kit-21`,
          }}
          meta={[{ name: "robots", content: "noindex, nofollow" }]}
        >
          <link rel="canonical" href={post.uri} />
          <link rel="icon" href={`/favicon.png`} sizes="32x32" />
          <link rel="icon" href={`/favicon.png`} sizes="192x192" />
          <link rel="apple-touch-icon" href={`/favicon.png`} />
          <link type="text/css" rel="stylesheet" href={styleTheme1} />
          <link type="text/css" rel="stylesheet" href={styleTheme2} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorFrontEnd}
          />
          <link type="text/css" rel="stylesheet" href={styleElementorConfig} />
          <link type="text/css" rel="stylesheet" href={styleElementorIcons} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorFontAwesome}
          />
          <link type="text/css" rel="stylesheet" href={styleElementorFASolid} />
          <link type="text/css" rel="stylesheet" href={styleElementorBrands} />
          <link type="text/css" rel="stylesheet" href={styleElementorPro} />
          <link
            type="text/css"
            rel="stylesheet"
            href={styleElementorGlobal}
            media="all"
          />
          {preLoadedCSS.map(css => {
            return (
              <link
                type="text/css"
                rel="stylesheet"
                href={`${site?.siteMetadata?.cmsURL}/wp-content/uploads/elementor/css/post-${css}.css?v=${cssVersion}`}
              />
            );
          })}
          <link
            type="text/css"
            rel="stylesheet"
            href={`${
              site?.siteMetadata?.cmsURL
            }/wp-content/uploads/elementor/css/post-${
              post.databaseId
            }.css?v=${new Date()}`}
          />
        </Helmet>

        <Header siteTitle={site?.siteMetadata?.siteTitle} />
        <div className="page-container-inner">
          {post?.content &&
            post?.content !== undefined &&
            parse(post.content, parserOptions)}
        </div>
        <Footer siteTitle={site?.siteMetadata?.siteTitle} />
      </div>
    </>
  );
};

export default PreviewPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteTitle
        cmsURL
      }
    }
  }
`;
